<template>
  <div>
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>

    <a-form :form="form" @submit="Submit">
      <div class="allcard formcard">
        <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
          <a-row :gutter="[15, 15]">
            <a-col :span="12">
              <div class="claimcard">
                <h1>Data Diri</h1>

                <a-row :gutter="[15, 15]">
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Nama Lengkap</label>
                        <p>{{ data.nama_lengkap }}</p>
                      </div>

                      <div>
                        <label>Nama Panggilan</label>
                        <p>{{ data.nama_panggilan }}</p>
                      </div>

                      <div>
                        <a-form-item label="Jenis Kelamin">
                          <a-select
                            v-decorator="[
                              'jenis_kelamin',
                              {
                                initialValue: data.jenis_kelamin,
                              },
                              // {
                              //   rules: [
                              //     {
                              //       required: true,
                              //     },
                              //   ],
                              // },
                            ]"
                            placeholder="Jenis Kelamin"
                          >
                            <a-select-option value="Pria"
                              ><a-icon type="man" /> Pria</a-select-option
                            >
                            <a-select-option value="Wanita"
                              ><a-icon
                                type="woman"
                                :style="{ color: '#DF5684' }"
                              />
                              Wanita</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                      </div>
                      <div>
                        <a-form-item label="Tanggal Lahir">
                          <a-date-picker
                            style="width: 100%"
                            autocomplete="off"
                            v-decorator="[
                              'tgllahir',
                              {
                                initialValue:
                                  this.data.tgllahir == null ||
                                  this.data.tgllahir == ''
                                    ? null
                                    : moment(this.data.tgllahir),
                              },
                            ]"
                            placeholder="Tanggal Lahir"
                            format="DD MMM YYYY"
                          />
                        </a-form-item>
                      </div>

                      <div class="_thr profileplan">
                        <label>Plan</label>
                        <div>
                          <div>
                            <ul v-if="chooseplan.benefit_existing != 'all'">
                              <li
                                v-for="(item, i) in JSON.parse(
                                  chooseplan.benefit_existing
                                )"
                                :key="i"
                                @click="Information(item)"
                              >
                                <img
                                  :src="
                                    require('@/assets/img/icon/' +
                                      item.replace(/\s/g, '').toLowerCase() +
                                      '.svg')
                                  "
                                />
                                {{ item }}
                              </li>
                            </ul>

                            <ul v-else>
                              <li
                                v-for="(item, i) in getAllPlan"
                                :key="i"
                                @click="Information(item)"
                              >
                                <img
                                  :src="
                                    require('@/assets/img/icon/' +
                                      item.replace(/\s/g, '').toLowerCase() +
                                      '.svg')
                                  "
                                />
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>No. Telepon</label>
                        <div class="groupcontact">
                          <div v-for="(item, i) in phone" :key="i">
                            <p>{{ item.phone }}</p>
                            <p>{{ item.label }}</p>
                          </div>
                          <!-- <div>
                          <p>021 768907089</p>
                          <p>Kantor</p>
                        </div>
                        <div>
                          <p>021 768907089</p>
                          <p>Kantor</p>
                        </div> -->
                        </div>
                      </div>

                      <div>
                        <label>Email</label>
                        <div class="groupcontact">
                          <div v-for="(item, i) in email" :key="i">
                            <p>{{ item.email }}</p>
                            <p>{{ item.label }}</p>
                          </div>
                          <!-- <div>
                          <p>office@mail.co.id</p>
                          <p>Kantor</p>
                        </div>
                        <div>
                          <p>office@mail.co.id</p>
                          <p>Kantor</p>
                        </div> -->
                        </div>
                      </div>

                      <div>
                        <label>Alamat</label>
                        <div class="groupcontact">
                          <div v-for="(item, i) in alamat" :key="i">
                            <p>
                              {{ item.alamat == null ? "-" : item.alamat }}
                            </p>
                            <p>{{ item.label }}</p>
                          </div>
                          <!-- <div>
                          <p>
                            Jl Wijaya no. 567, Kecamatan, Kota, kabupaten
                            Propinsi
                          </p>
                          <p>Pribadi</p>
                        </div> -->
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="claimcard">
                <div v-for="(item, i) in group" :key="i">
                  <h1>{{ `${item.nama_group} - ${item.jenis_asuransi}` }}</h1>

                  <a-carousel
                    :slidesToScroll="3"
                    :slidesToShow="3"
                    :dots="false"
                    :draggable="true"
                    arrows
                  >
                    <div
                      slot="prevArrow"
                      class="custom-slick-arrow"
                      style="left: -21px; top: 33px; z-index: 2"
                    >
                      <a-icon type="left-circle" />
                    </div>
                    <div
                      slot="nextArrow"
                      class="custom-slick-arrow"
                      style="right: -21px; top: 33px"
                    >
                      <a-icon type="right-circle" />
                    </div>

                    <div
                      class="cardgroup"
                      v-for="(nItem, ii) in item.perusahaan"
                      :key="ii"
                    >
                      <div class="sec-data-pasien">
                        <div>
                          <label>Perusahaan {{ ii + 1 }}</label>
                          <p>{{ nItem.nama_perusahaan }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="cardgroup">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Perusahaan 1</label>
                        <p>Tudung Perjuangan</p>
                      </div>
                    </div>
                  </div>
                  <div class="cardgroup">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Perusahaan 1</label>
                        <p>Tudung Perjuangan</p>
                      </div>
                    </div>
                  </div>
                  <div class="cardgroup">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Perusahaan 1</label>
                        <p>Tudung Perjuangan</p>
                      </div>
                    </div>
                  </div> -->
                  </a-carousel>
                </div>
              </div>
            </a-col>
          </a-row>
        </simplebar>
      </div>
      <div class="submitcard">
        <a-button type="default" @click="batalkan">Batalkan</a-button>
        <a-button type="primary" html-type="submit">Submit</a-button>
      </div>
    </a-form>
    <Plan ref="plan" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import Plan from "@/components/Modal/plan.vue";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormDetailProfile" });
  },
  components: {
    Plan,
    simplebar,
  },
  data() {
    return {
      data: {},
      alamat: [],
      email: [],
      group: [],
      perusahaan: [],
      phone: [],
      chooseplan: {},
      breadcrumb: [
        {
          name: "Detail Profile",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$store.state.Auth.data.user.id;
    },
    getAllPlan() {
      return this.$store.state.Auth.data.perusahaan
        .map((key) => JSON.parse(key.benefit_existing).toString())
        .filter(this.onlyUnique)
        .toString()
        .split(",")
        .filter(this.onlyUnique);
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    moment,
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    Information(e) {
      //alert("tes");
      this.$refs.plan.Show(e);
    },
    fetch() {
      this.$store
        .dispatch("GetDetailProfile", {
          id: this.id,
        })
        .then((response) => {
          //console.log(response);
          this.data = response.data.data;
          this.alamat = response.data.alamat;
          this.email = response.data.email;
          this.group = response.data.group;
          this.perusahaan = response.data.perusahaan;
          this.phone = response.data.phone;
          this.chooseplan = JSON.parse(localStorage.getItem("company"));
        });

      console.log(moment(this.data.tgllahir).format("YYYY-MM-DD"));
    },
    batalkan() {
      this.$router.push({ name: "Home" });
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store
            .dispatch("UpdateProfile", {
              id: this.id,
              tgllahir:
                values.tgllahir == null
                  ? ""
                  : moment(values.tgllahir).format("YYYY-MM-DD"),
              jenis_kelamin: values.jenis_kelamin,
            })
            .then((response) => {
              this.$notification.success({
                message: response.data.message,
              });

              this.$router.push({ name: "Home" });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
/* For demo */

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: rgb(37 37 38 / 39%);
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
